<template data-app>
  <div>
    <v-overlay :value="true">
      <v-progress-circular
        :size="70"
        :width="7"
        color="amber"
        indeterminate
        class="spinner"
      />
    </v-overlay>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Login Success
        </v-card-title>
        <vue-json-pretty
          :path="'res'"
          :data="user"
        />

        <v-divider />

        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="_redirectToLogin"
          >
            Logout
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import axios from 'axios'
  import VueJsonPretty from 'vue-json-pretty'
  import 'vue-json-pretty/lib/styles.css'

  export default {
    name: 'LandingIndex',

    components: {
      VueJsonPretty,
    },
    data () {
      return {
        firebaseCloudFunctionBaseUrl: process.env.VUE_APP_FIREBASE_CLOUD_FUNCTION_BASE_URL,
        dialog: false,
        user: null,
      }
    },
    computed: {},
    created () {
      this._getUserInfo()
      this.dialog = false
    },
    methods: {
      _redirectToLogin () {
        this.$router.push('/login')
      },
      _extractParams () {
        let {
          location: { search: queryParamString },
        } = window
        const params = {}
        if (queryParamString.length > 1 && queryParamString.indexOf('?') > -1) {
          queryParamString = queryParamString.replace('?', '')
          if (queryParamString.indexOf('&') === -1) {
            // Contains only one param
            const paramParts = queryParamString.split('=')
            params[paramParts[0]] = paramParts[1]
          } else {
            // Contains multiple params
            const queryParams = queryParamString.split('&')
            queryParams.forEach((queryParam) => {
              const paramParts = queryParam.split('=')
              params[paramParts[0]] = paramParts[1]
            })
          }
        }
        return params
      },
      async _getUserInfo () {
        // First decode code
        const { code } = this._extractParams()
        try {
          if (code && code.length) {
            // Get token from code
            const { data: authenticatedUser } = await axios.post(`${this.firebaseCloudFunctionBaseUrl}/linkedInLogin`, {
              code,
              redirectUri: process.env.VUE_APP_LINKEDIN_LOGIN_REDIRECT_URL,
            })
            localStorage.setItem('username', authenticatedUser.userEmail)
            localStorage.setItem('token', authenticatedUser.token)
            this.$router.push('/pages/senders')
            this.dialog = true
          } else {
            // No code
            // Redirect to login page
            this.$router.push('/login')
          }
        } catch (error) {
          // Redirect to login page
          this.$router.push('/login')
        }
      },
    },
  }
</script>

<style scoped>
.v-dialog__container {
  display: unset;
}
</style>
